import { Grid } from '@mui/material';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  getAttributeMetaByIdCallback,
  postAttributeMetaCallback,
  putAttributeMetaCallback,
  removeNullishOrEmptyFields,
  AttributeMetaClass,
  attributeMetaLoaderState,
  attributeMetaState,
  getAttributesMetaByEntityTypeCallback,
  ATTRIBUTE_META_TYPES,
  getAllQuestionPageCallback,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { useEffect } from 'react';
import { LBTButton, LBTSelect, LBTTextField } from '@laborability/components';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../Routes';

export const LABEL_ATTRIBUTE_META_TYPE = {
  number: 'Numerico',
  string: 'Testuale',
  bool: 'Booleano',
  date: 'Data',
};

interface Props {
  id?: number;
  extraParams: { entity_type?: string };
}

export function AttributeMetaForm({ id, extraParams }: Props) {
  const navigate = useNavigate();
  const getAllPage = useRecoilCallback(getAllQuestionPageCallback, []);
  const postAttributeMeta = useRecoilCallback(postAttributeMetaCallback, []);
  const putAttributeMeta = useRecoilCallback(putAttributeMetaCallback, []);
  const getAttributeMeta = useRecoilCallback(getAttributeMetaByIdCallback, []);
  const getAllAttributesMeta = useRecoilCallback(
    getAttributesMetaByEntityTypeCallback,
    [],
  );
  const [current, setCurrent] = useRecoilState(attributeMetaState);
  const attributeMetaLoader = useRecoilValue(attributeMetaLoaderState);

  const resetFormState = () => {
    setCurrent(new AttributeMetaClass());
  };

  useEffect(() => {
    if (id) getAttributeMeta({ id });
    else resetFormState();
  }, [id]);

  return (
    <ModalForm
      defaultValues={{
        ...new AttributeMetaClass(),
        entity_type: extraParams.entity_type,
      }}
      isLoading={attributeMetaLoader}
      currentValues={current}
      validation={values => {
        return {
          ...(values.attribute_name === ''
            ? { attribute_name: "Il nome dell'attributo è obbligatorio" }
            : {}),
          ...(values.attribute_type === ''
            ? { attribute_type: "Il tipo dell'attributo è obbligatorio" }
            : {}),
          /* ...(values.entity_type === ''
            ? { entity_type: "L'entità è obbligatoria" }
            : {}), */
        };
      }}
      handleSubmit={async values => {
        if (values.id)
          return await putAttributeMeta({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
          });
        return await postAttributeMeta(removeNullishOrEmptyFields(values));
      }}
      resetGridState={async () => {
        if (extraParams.entity_type)
          await getAllAttributesMeta({ entity_type: extraParams.entity_type });
      }}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleBlur, setFieldValue }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Nome"
              type="text"
              name="attribute_name"
              onChange={value => setFieldValue('attribute_name', value)}
              onBlur={handleBlur}
              value={values.attribute_name}
              required
              error={!!errors?.attribute_name}
              helperText={errors?.attribute_name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Label"
              type="text"
              name="label"
              onChange={value => setFieldValue('label', value)}
              onBlur={handleBlur}
              value={values.label}
              required
              error={!!errors?.label}
              helperText={errors?.label}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="attribute_type-select"
              name="attribute_type"
              value={values.attribute_type ?? undefined}
              label="Tipo"
              handleChange={e => setFieldValue('attribute_type', e)}
              onBlur={handleBlur}
              fullWidth
              required
              error={!!errors?.attribute_type}
              helperText={errors?.attribute_type}
              items={ATTRIBUTE_META_TYPES.map(item => ({
                id: item,
                name: LABEL_ATTRIBUTE_META_TYPE[item],
              }))}
            />
          </Grid>
          {/*  <Grid item mobile={12}>
            <LBTSelect
              id="entity_type-select"
              name="entity_type"
              value={values.entity_type ?? undefined}
              label="Entità"
              handleChange={e => setFieldValue('entity_type', e)}
              onBlur={handleBlur}
              disabled={!!values.id}
              fullWidth
              required
              error={!!errors?.entity_type}
              helperText={errors?.entity_type}
              items={ENTITY_TYPES.map(item => ({
                id: item,
                name: LABEL_ENTITY_TYPE[item],
              }))}
            />
          </Grid> */}
          {!!values.questions?.length && (
            <Grid item mobile={12}>
              <LBTButton
                variant="outlined"
                onClick={async () => {
                  const question = values.questions?.filter(
                    item => !!item.flow_id && !!item.page_id,
                  );
                  const pageId = question?.[0].page_id;
                  const flowId = question?.[0].flow_id;
                  if (flowId) {
                    await getAllPage({ id: flowId });
                  }
                  navigate(`/${APP_ROUTES.QUESTION_PAGE}/${pageId}`);
                }}
              >
                Vai alla domanda collegata
              </LBTButton>
            </Grid>
          )}
        </>
      )}
    </ModalForm>
  );
}
